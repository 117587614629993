<template>
  <v-card>
    <v-card-text>
      <h3>Confirmation Text</h3>
      <p>This is the text that will be displayed to the end user upon successful submission of the form.</p>
      <html-editor v-model="confText" :merge-fields="formFields"></html-editor>
      <v-row>
        <v-col cols="12">
          <v-checkbox v-model="again" label="Include a button where the user can submit the form again. The form will reset to be blank."></v-checkbox>
        </v-col>
      </v-row>
      <h3 style="margin-top:1.5em">Confirmation Email</h3>
      <p>Whether to send a confirmation email to the user and, if so, the additional details for that email.</p>
      <v-row>
        <v-col cols="12" md="4">
          <v-checkbox v-model="emailUser.active" label="Send confirmation email to user" hide-details></v-checkbox>
        </v-col>
        <v-col v-if="emailUser.active" cols="12" md="4">
          <v-combobox v-model="emailUser.from" :items="emailFromOptions" :rules="[v => !!v || 'Required' ]" label="From Email" outlined hide-details></v-combobox>
        </v-col>
        <v-col v-if="emailUser.active" cols="12" md="4">
          <v-select v-model="emailUser.toField" :items="emailToOptions" label="To Email Field" outlined hide-details multiple chips small-chips deletable-chips></v-select>
        </v-col>
        <v-col v-if="emailUser.active" cols="12">
          <v-text-field v-model="emailUser.subject" label="Subject" outlined hide-details></v-text-field>
        </v-col>
        <v-col v-if="emailUser.active" cols="12">
          <html-editor v-model="emailUser.body" :merge-fields="formFields"></html-editor>
        </v-col>
        <v-col v-if="emailUser.active" cols="12">
          <v-checkbox v-model="emailUser.includeData" label="Include submitted form data in the confirmation email"></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn :disabled="confText === '' || (emailUser.active && (emailUser.from === '' || emailUser.toField.length === 0 || emailUser.subject === '' || emailUser.body === ''))" color="success" @click="save">
        <v-icon left>fal fa-save</v-icon>
        Save Changes
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { ref, computed, watch } from '@vue/composition-api'
export default {
  components: {
    HtmlEditor: () => import('@/components/forms/HTMLEditor')
  },
  props: {
    formId: {
      type: String,
      required: true
    },
    revisionId: {
      type: String,
      required: true
    }
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const confText = ref('')
    const again = ref(false)
    const formTitle = ref('')
    const access = ref({
      loginRequired: false,
      roles: []
    })
    const emailUser = ref({
      active: false,
      from: '',
      toField: [],
      subject: '',
      body: '',
      includeData: false
    })
    const toField = ref([])
    const notifications = ref([])
    const emailFromOptions = computed(() => {
      let arr = []
      const { roles } = user.value
      if (roles.filter((role) => role === 'Financial Aid').length > 0) arr.push({ text: 'Financial Aid <financialaid@covenant.edu>', value: 'financialaid@covenant.edu' })
      if (roles.filter((role) => role === 'Human Resources').length > 0) arr.push({ text: 'HR Assistant <hrassistant@covenant.edu>', value: 'hrassistant@covenant.edu' })
      if (roles.filter((role) => role === 'Alumni').length > 0) arr.push({ text: 'Alumni Office <alumni@covenant.edu>', value: 'alumni@covenant.edu' })
      if (roles.filter((role) => role === 'Accounting').length > 0) arr.push({ text: 'Accounting <accounting@covenant.edu>', value: 'accounting@covenant.edu' })
      arr.push({ text: 'Covenant Reports <report-email@covenant.edu>', value: 'report-email@covenant.edu' })
      return arr
    })

    const formFields = ref([])
    const emailToOptions = ref([])

    watch(() => props.formId, () => {
      if (props.formId != null && props.formId !== '') {
        root.$feathers.service('forms/base').get(props.formId).then((data) => {
          formTitle.value = data.name
          again.value = data.again || false
          if ('access' in data) {
            access.value.loginRequired = data.access.loginRequired || false
            access.value.roles = data.access.roles || []
            if (access.value.loginRequired) {
              if (emailToOptions.value.length === 0) {
                emailToOptions.value.push({ text: 'Logged-in User\'s Email', value: 'user::email' })
              } else if (emailToOptions.value[0].value !== 'user::email') {
                emailToOptions.value.splice(0, 0, { text: 'Logged-in User\'s Email', value: 'user::email' })
              }
            } else if (emailToOptions.value[0].value === 'user::email') {
              emailToOptions.value.splice(0, 1)
            }
          }
          if ('emailUser' in data) {
            for (let l in emailUser.value) {
              if (l in data.emailUser) {
                if (l === 'toField') {
                  if (data.emailUser[l] === '') continue
                  else if (!Array.isArray(data.emailUser[l])) {
                    emailUser.value[l] = [data.emailUser[l]]
                  } else {
                    emailUser.value[l] = data.emailUser[l]
                  }
                } else {
                  emailUser.value[l] = data.emailUser[l]
                }
              } else {
                emailUser.value[l] = (l === 'active' || l === 'includeData' ? false : '')
              }
            }
          }
          if ('notifications' in data && Array.isArray(data.notifications) && data.notifications.length > 0) {
            data.notifications.forEach((row) => notifications.value.push(row))
          }
        })
      }
    })

    watch(() => props.revisionId, () => {
      if (props.revisionId != null && props.revisionId !== '') {
        root.$feathers.service('forms/revision').get(props.revisionId).then((data) => {
          if ('confText' in data) confText.value = data.confText
          else {
            let title = formTitle.value
            if (title.trim().substr(-4).toLowerCase() !== 'form') title += ' form'
            confText.value = 'Thank you for submitting the ' + formTitle.value + '. Your submission was successful.'
          }
          formFields.value = []
          data.sections.forEach(({ inputs }) => inputs.forEach(({ label, input }) => {
            formFields.value.push(label)
            if (input === 'email') emailToOptions.value.push({ text: label, value: label })
          }))
        })
      }
    })

    function emailRule (v) {
      return v === '' || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Invalid email'
    }

    const newEmail = ref('')
    function addEmail () {
      if (emailRule(newEmail.value) === true) {
        notifications.value.push({ email: newEmail.value, limitDaily: false })
        newEmail.value = ''
      }
    }

    function save () {
      let basePatch = { emailUser: { ...emailUser.value }, again: again.value }
      if ('from' in emailUser.value && typeof (emailUser.value.from) === 'object') {
        basePatch.emailUser.from = basePatch.emailUser.from.value
      }
      root.$feathers.service('forms/base').patch(props.formId, basePatch).then(() => {
        let revisionPatch = { confText: confText.value }
        root.$feathers.service('forms/revision').patch(props.revisionId, revisionPatch).then(() => {
          root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Confirmation details saved successfully' })
        })
      })
    }

    return {
      confText,
      again,
      access,
      emailUser,
      toField,
      formFields,
      emailToOptions,
      notifications,
      emailFromOptions,
      emailRule,
      newEmail,
      addEmail,
      save
    }
  }
}
</script>
